import React, { useEffect, useState } from "react";
import { kebabCase, union, uniqBy } from "lodash";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link, graphql, PageProps } from "gatsby";

import Layout from "../../components/layout/Layout";

import PageWrapper from "../../components/layout/PageWrapper";
import PageTitle from "../../components/layout/PageTitle";
import Tags from "../../components/cms/Tags";

type Tag = {
  fieldValue: string
}

type Props = PageProps & {
  data: {
    site: {
      siteMetadata: {
        title: string;
      }
    },
    allMdx: {
      group: Tag[]
    }
  }
}

const parseTags = (tags: Tag[]) => uniqBy(union(tags), "fieldValue").map((t => t.fieldValue));

const TagsPage = ({ data, location }: Props) => {
  const { t } = useTranslation();
  const [tags, setTags] = useState<string[]>([]);

  const {
    allMdx: { group: articleTags },
    site: {
      siteMetadata: { title },
    },
  } = data;

  useEffect(() => {
    if (articleTags) {
      setTags(parseTags(articleTags));
    }
  }, [articleTags]);

  return (
    <Layout location={location}>
      <Helmet
        title={`${t("cms.tags")} | ${title}`}
        bodyAttributes={{
          type: "tags",
        }}
      />
      <PageWrapper>
        <PageTitle heading={t("cms.tags")} />
        <Tags data={tags} />
      </PageWrapper>
    </Layout>
  );
};

export default TagsPage;

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
  }
`;
