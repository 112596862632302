import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { kebabCase } from "lodash";

const TagsContainer = styled.div`
  display: flex;
  margin-top: 48px;
  margin-bottom: 48px;
  flex-wrap: wrap;
`;

const Tag = styled(Link)`
  background-color: ${props => props.theme.colors.gray4};
  border-radius: 8px;
  padding: 4px 14px;
  transition: all 0.2s;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.gray0};
  margin-right: 16px;
  margin-bottom: 12px;
  opacity: .7;

  &:hover {
    opacity: 1;
  }
`;

type Props = {
  data?: string[];
}

const Tags = ({ data = [] }: Props) => data?.length ? (
  <TagsContainer>
    {data.map((tag) => (
      <Tag
        to={`/tags/${kebabCase(tag)}/`}
        key={tag + `tag`}
      >
        {tag}
      </Tag>
    ))}
  </TagsContainer>
) : <></>;

export default Tags;
